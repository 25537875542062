import {
  Button,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  Switch,
  TextField,
  styled as muiStyled,
  Checkbox as MuiCheckbox,
  Chip,
  FormControl,
  ListItemText,
  Stack,
  SwitchProps,
  Avatar,
} from "@mui/material";
import styled from "styled-components";
import { Controller, useWatch } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormBox, FormLabel, Text } from "../../helpers/generalStyles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import { MuiTelInput } from "mui-tel-input";
import moment from "moment";
import {
  checkFileMaxSize,
  checkImageMinResolution,
  isArrayEmpty,
} from "../../helpers/helpers";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";
import {
  getSelectedImagesForPlaceholderDisplay,
  getSelectedLabelsForPlaceholderDisplay,
} from "./inputs.utils";
import { Options } from "./inputs.types";

export function InputText(props: {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  id: string;
  control: any;
  inputType?: "number" | "decimal" | "text" | "time" | "date";
  multiLine?: boolean;
  error?: any;
  t: any;
  sx?: any;
  suffix?: any;
  min?: number;
  max?: number;
  hide?: boolean;
  onChange?: Function;
  placeholder?: string;
  size?: "small" | "medium";
}) {
  const {
    label,
    required,
    placeholder,
    disabled,
    name,
    id,
    control,
    inputType,
    multiLine,
    error,
    t,
    sx,
    suffix,
    min,
    max,
    hide,
    onChange = () => {},
    size,
  } = props;

  const value = useWatch({
    control,
    name: name,
  });

  const isTypeNumber = inputType === "number" || inputType === "decimal";

  return (
    <FormBox style={{ display: hide ? "none" : undefined }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            size={size}
            inputProps={{
              type: isTypeNumber ? "number" : inputType,
              inputMode: isTypeNumber ? "decimal" : "text",
              min,
              step: isTypeNumber
                ? inputType === "decimal"
                  ? 0.01
                  : 1
                : undefined,
              max,
            }}
            sx={sx || {}}
            // required={required}
            placeholder={placeholder}
            disabled={disabled}
            label={label}
            id={id}
            multiline={multiLine ? multiLine : false}
            rows={4}
            variant="outlined"
            fullWidth={true}
            type={isTypeNumber ? "decimal" : inputType}
            error={Boolean(error)}
            helperText={error ? t(error) : ""}
            InputProps={{
              endAdornment: suffix,
            }}
            value={value || value === 0 ? value : ""}
            onChange={(event) => {
              const value = event.target.value;
              if (value === "") {
                field.onChange(null);
                onChange(null);
              } else {
                if (isTypeNumber) {
                  field.onChange(Number(value));
                  onChange(Number(value));
                } else {
                  field.onChange(value);
                  onChange(value);
                }
              }
            }}
          />
        )}
      />
    </FormBox>
  );
}

export function BigInputText(props: {
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  id: string;
  control: any;
  inputType?: "number" | "text" | "time" | "date";
  multiLine?: boolean;
  error?: any;
  t: any;
}) {
  const {
    placeholder,
    required,
    disabled,
    name,
    id,
    control,
    inputType,
    multiLine,
    error,
    t,
  } = props;
  return (
    <FormBox>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            // required={required}
            placeholder={`${placeholder} ${required ? "*" : ""}`}
            disabled={disabled}
            id={id}
            style={{
              backgroundColor: "#E1F9F3",
              borderRadius: "10px",
              maxWidth: "600px",
              height: "80px",
            }}
            multiline={multiLine ? multiLine : false}
            rows={4}
            variant="standard"
            fullWidth={true}
            type={inputType ? inputType : "text"}
            error={Boolean(error)}
            helperText={error ? t(error) : ""}
            inputProps={{
              style: {
                fontSize: 26,
                height: 70,
              },
            }}
            InputLabelProps={{ style: { fontSize: 26 } }}
            {...field}
          />
        )}
      />
    </FormBox>
  );
}

export function InputPhone(props: {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  id: string;
  control: any;
  error?: any;
  t: any;
  style?: any;
}) {
  const {
    label,
    required,
    disabled,
    name,
    id,
    control,
    error,
    t,
    style = {},
  } = props;

  const pattern = "^+?[1-9]d{1,3}s?d{4,14}(?:s?(?:x|ext|extension)s?d+)?$";

  return (
    <FormBox style={style || {}}>
      <Text style={{ marginLeft: "8px" }} weight={700} size="20px">
        {label}
      </Text>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl
            style={{ margin: 0 }}
            fullWidth
            required={required}
            error={Boolean(error)}
          >
            <MuiTelInput
              {...field}
              disabled={disabled}
              fullWidth
              id={id}
              variant="outlined"
              inputProps={{
                pattern,
              }}
            />
            <FormHelperText>{error ? t(error) : ""}</FormHelperText>
          </FormControl>
        )}
      />
    </FormBox>
  );
}

export function Select(props: {
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputType?: "number";
  name: string;
  id: string;
  options: { value: string; label: string; disabled?: boolean }[];
  control: any;
  error: any;
  t: any;
  resetSelection?: boolean;
}) {
  const {
    label,
    placeholder,
    required,
    disabled,
    inputType,
    name,
    id,
    options,
    control,
    error,
    t,
    resetSelection,
  } = props;
  return (
    <FormBox>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth={true}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
              {...field}
              label={label}
              displayEmpty
              disabled={disabled}
              id={id}
              variant="outlined"
              error={Boolean(error)}
              renderValue={(selected) => {
                if (selected === "" || selected === t("remove_filter"))
                  return <em>{placeholder}</em>;
                else {
                  const selectedLabel = options.find(
                    (item) => item.value === String(selected),
                  )?.label;
                  return inputType === "number"
                    ? Number(selectedLabel)
                    : selectedLabel;
                }
              }}
              onChange={(event) => {
                if (inputType === "number") {
                  const value = event.target.value;
                  field.onChange(value === "" ? null : Number(value));
                } else {
                  field.onChange(event);
                }
              }}
            >
              <MenuItem disabled value="">
                <em>{placeholder}</em>
              </MenuItem>
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  disabled={option.disabled}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              {resetSelection && (
                <MenuItem value={""}>
                  <span style={{ color: "#008670", margin: "0 auto" }}>
                    {t("remove_filter")}
                  </span>
                </MenuItem>
              )}
            </MuiSelect>
            <FormHelperText>{error ? t(error) : ""}</FormHelperText>
          </FormControl>
        )}
      />
    </FormBox>
  );
}

export function InputMultiSelect(props: {
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  id: string;
  options: { value: string; label: string }[];
  error: any;
  t: any;
  onChange: (value: any) => void;
  value: Array<string>;
  sx?: any;
  resetSelection?: boolean;
  autoComplete?: string;
}) {
  const {
    label,
    placeholder,
    required,
    disabled,
    name,
    id,
    options,
    error,
    t,
    onChange,
    value,
    sx,
    resetSelection,
    autoComplete,
  } = props;
  const defaultSx = {
    ...sx,
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const selectedValues = (event?.target?.value as string[]) || [];
    if (selectedValues.includes(t("remove_filter"))) {
      event.target.value = [];
    }
    onChange(event);
  };

  return (
    <FormBox>
      <FormControl sx={defaultSx}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          value={value}
          required={required}
          name={name}
          multiple
          onChange={handleChange as any}
          label={label}
          displayEmpty
          disabled={disabled}
          id={id}
          variant="outlined"
          autoComplete={autoComplete ?? "on"}
          error={Boolean(error)}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {resetSelection && (
            <MenuItem value={t("remove_filter")}>
              <span style={{ color: "#008670", margin: "0 auto" }}>
                {t("remove_filter")}
              </span>
            </MenuItem>
          )}
        </MuiSelect>
      </FormControl>
    </FormBox>
  );
}

export function InputCheckboxSelect(props: {
  disabled?: boolean;
  multiple: boolean;
  options: Options;
  placeholder?: string;
  onChange: any;
  values: string[];
  resetSelection?: boolean;
  id: string;
  name: string;
  required?: boolean;
  error: any;
  actionBtnLabel?: string;
  actionBtnFct?: Function;
  actionBtnStyle?: Object;
}) {
  const {
    multiple,
    disabled,
    values,
    placeholder,
    options,
    onChange,
    resetSelection,
    id,
    name,
    required,
    error,
    actionBtnLabel,
    actionBtnFct,
    actionBtnStyle,
  } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("agenda");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    event.target.value = (event.target.value as string[])?.filter(Boolean);
    onChange(event);
  };

  return (
    <FormBox>
      <MuiSelect
        name={name}
        id={id}
        multiple={multiple}
        displayEmpty
        disabled={disabled}
        value={values}
        required={required}
        error={error}
        renderValue={(selected) => {
          if (isArrayEmpty(selected)) {
            return <>{placeholder && <span>{placeholder}</span>}</>;
          } else {
            const selectedLabels = getSelectedLabelsForPlaceholderDisplay(
              selected,
              options,
            );
            return <span>{selectedLabels}</span>;
          }
        }}
        onChange={handleChange as any}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{ borderRadius: "12px", margin: "8px", width: "calc(100% - 16px)" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{ fontSize: "14px" }}
            disabled={option?.disabled}
          >
            <MuiCheckbox
              checked={values.indexOf(String(option.value)) > -1}
              sx={{ paddingBlock: "0" }}
            />
            <ListItemText primary={option.label} sx={{ fontSize: "14px" }} />
          </MenuItem>
        ))}

        {actionBtnLabel && isFunction(actionBtnFct) ? (
          <div style={{ width: "100%", display: "flex" }}>
            <button onClick={actionBtnFct} style={actionBtnStyle}>
              {actionBtnLabel}
            </button>
          </div>
        ) : (
          <Stack
            direction={"row"}
            justifyContent={"center"}
            gap={2}
            sx={{ paddingTop: 1 }}
          >
            {resetSelection && (
              <MenuItem
                value={t("remove_filter")}
                sx={{
                  justifyContent: "center",
                  color: "rgb(0, 134, 112)",
                  fontWeight: 600,
                }}
                onClick={() => {
                  onChange({ target: { value: [] } });
                  handleClose();
                }}
              >
                {t("remove_filter")}
              </MenuItem>
            )}
            <MenuItem
              value={t("ok")}
              sx={{
                padding: 0,
              }}
            >
              <Button variant="contained" color="info" onClick={handleClose}>
                {t("ok")}
              </Button>
            </MenuItem>
          </Stack>
        )}
      </MuiSelect>
    </FormBox>
  );
}

export function InputCheckboxImageSelect(props: {
  disabled?: boolean;
  multiple: boolean;
  options: Options;
  placeholder?: string;
  onChange: any;
  values: string[];
  resetSelection?: boolean;
  id: string;
  name: string;
  required?: boolean;
  error: any;
  actionBtnLabel?: string;
  actionBtnFct?: Function;
  actionBtnStyle?: Object;
}) {
  const {
    multiple,
    disabled,
    values,
    placeholder,
    options,
    onChange,
    resetSelection,
    id,
    name,
    required,
    error,
    actionBtnLabel,
    actionBtnFct,
    actionBtnStyle,
  } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("agenda");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    event.target.value = (event.target.value as string[])?.filter(Boolean);
    onChange(event);
  };

  return (
    <FormBox>
      <MuiSelect
        name={name}
        id={id}
        multiple={multiple}
        displayEmpty
        disabled={disabled}
        value={values}
        required={required}
        error={error}
        renderValue={(selected) => {
          if (isArrayEmpty(selected)) {
            return <>{placeholder && <span>{placeholder}</span>}</>;
          } else {
            return getSelectedImagesForPlaceholderDisplay(selected, options);
          }
        }}
        onChange={handleChange as any}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{
          borderRadius: "12px",
          margin: "8px",
          width: "calc(100% - 16px)",
          height: "60px",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{ fontSize: "14px" }}
            disabled={option?.disabled}
          >
            <Avatar alt={option.label} src={option.avatar} variant="rounded" />
            <ListItemText
              primary={option.label}
              sx={{ fontSize: "14px", marginLeft: "8px" }}
            />
            <MuiCheckbox
              checked={values.indexOf(String(option.value)) > -1}
              sx={{ paddingBlock: "0" }}
            />
          </MenuItem>
        ))}

        {actionBtnLabel && isFunction(actionBtnFct) ? (
          <div style={{ width: "100%", display: "flex" }}>
            <button onClick={actionBtnFct} style={actionBtnStyle}>
              {actionBtnLabel}
            </button>
          </div>
        ) : (
          <Stack
            direction={"row"}
            justifyContent={"center"}
            gap={2}
            sx={{ paddingTop: 1 }}
          >
            {resetSelection && (
              <MenuItem
                value={t("remove_filter")}
                sx={{
                  justifyContent: "center",
                  color: "rgb(0, 134, 112)",
                  fontWeight: 600,
                }}
                onClick={() => {
                  onChange({ target: { value: [] } });
                  handleClose();
                }}
              >
                {t("remove_filter")}
              </MenuItem>
            )}
            <MenuItem
              value={t("ok")}
              sx={{
                padding: 0,
              }}
            >
              <Button variant="contained" color="info" onClick={handleClose}>
                {t("ok")}
              </Button>
            </MenuItem>
          </Stack>
        )}
      </MuiSelect>
    </FormBox>
  );
}

export function InputCalendar(props: {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  control: any;
  error: any;
  t: any;
}) {
  const { label, required, disabled, name, control, error, t } = props;
  return (
    <FormBox>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth={true}>
            <DatePicker
              disabled={disabled}
              {...field}
              disablePast
              value={moment(field.value)}
              slotProps={{ textField: { size: "medium", variant: "standard" } }}
            />
            <FormHelperText>{error ? t(error) : ""}</FormHelperText>
          </FormControl>
        )}
      />
    </FormBox>
  );
}

const VisuallyHiddenInput = muiStyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  color: "#2CA58D",
});
const StyledInfosContainer = styled.div({
  display: "grid",
  gridGap: "8px",
  marginLeft: "10px",
});

export function InputFile(props: {
  label: string;
  onAddFile: any;
  error: any;
  t: any;
  required?: boolean;
  disabled?: boolean;
  fileTypes?: string;
  fileSpec?: string;
}) {
  const {
    label,
    required,
    disabled,
    onAddFile,
    error,
    t,
    fileTypes,
    fileSpec,
  } = props;
  const initialFileControl = {
    size: true,
    resolution: true,
  };
  const [fileControl, setFileControl] = useState<any>(initialFileControl);

  const onChangePicture = async (e: any) => {
    const files: any[] = Array.from(e.target.files || []);

    if (!isArrayEmpty(files)) {
      const maxImgSizeAllowed = 2097152; //2Mo
      const minHeight = 480;
      const minWidth = 720;
      setFileControl(initialFileControl);
      for (const file of files) {
        const isFileSizeValid = checkFileMaxSize(file, maxImgSizeAllowed);
        const isImageResolutionValid = await checkImageMinResolution(
          file,
          minHeight,
          minWidth,
        );
        setFileControl({
          resolution: isImageResolutionValid,
          size: isFileSizeValid,
        });

        if (isFileSizeValid && isImageResolutionValid) {
          const base64File = await convertFileToBase64(file);
          const validFile = {
            title: file.name,
            mimeType: file.type,
            base64File,
            url: URL.createObjectURL(file),
          };
          onAddFile(validFile);
        }
      }
    }
  };

  const sizeErrorMsg = useMemo(
    () => (fileControl.size ? "" : t("file_size_max_limit")),
    [fileControl.size],
  );
  const resolutionErrorMsg = useMemo(
    () => (fileControl.resolution ? "" : t("image_resolution_min_limit")),
    [fileControl.resolution],
  );

  return (
    <FormBox>
      <FormLabel>{`${label.toUpperCase()} ${required ? "*" : ""}`}</FormLabel>
      <div
        style={{ flex: 3, display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={disabled}
            style={{
              backgroundColor: "#E1F9F3",
              borderRadius: "10px",
              color: "#2CA58D",
            }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            <VisuallyHiddenInput
              type="file"
              accept={fileTypes}
              onChange={onChangePicture}
              multiple
            />
          </Button>
        </div>
        <StyledInfosContainer>
          {(sizeErrorMsg || resolutionErrorMsg) && (
            <FormHelperText>
              {sizeErrorMsg || resolutionErrorMsg}
            </FormHelperText>
          )}

          {error && !sizeErrorMsg && !resolutionErrorMsg && (
            <>
              {Object.keys(error).map((key, index) => (
                <React.Fragment key={index}>
                  {key === "message" && (
                    <FormHelperText>{t(error[key])}</FormHelperText>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </StyledInfosContainer>
      </div>
    </FormBox>
  );
}

const convertFileToBase64 = (file: any) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Result = String(reader.result)?.split(",")[1]; // Extract base64File string from data URL
      resolve(base64Result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export function Checkbox(props: {
  id: string;
  name: string;
  label: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
  t: any;
  uppercase?: boolean;
  labelClassName?: string;
}) {
  const {
    id,
    name,
    label,
    control,
    required,
    disabled,
    t,
    uppercase = true,
    labelClassName,
  } = props;
  return (
    <FormBox>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <MuiCheckbox
              disabled={disabled}
              id={id}
              checked={field.value}
              {...field}
            />
            {label ? (
              <FormLabel
                className={labelClassName}
              >{`${uppercase ? label.toUpperCase() : label} ${required ? "*" : ""}`}</FormLabel>
            ) : null}
          </div>
        )}
      />
    </FormBox>
  );
}

export function ControlledSwitch(props: {
  id: string;
  name: string;
  label: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
  t: any;
  uppercase?: boolean;
  labelClassName?: string;
  style?: any;
}) {
  const {
    id,
    name,
    label,
    control,
    required,
    disabled,
    t,
    uppercase,
    labelClassName,
    style,
  } = props;
  return (
    <FormBox>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              ...style,
            }}
          >
            <Switch
              id={id}
              checked={field.value}
              disabled={disabled}
              {...field}
            />
            {label ? (
              <FormLabel
                className={labelClassName}
              >{`${uppercase ? label.toUpperCase() : label} ${required ? "*" : ""}`}</FormLabel>
            ) : null}
          </div>
        )}
      />
    </FormBox>
  );
}

export function UncontrolledSwitch(props: {
  label?: string;
  isActive?: boolean;
  required?: boolean;
  onChange: any;
  disabled?: boolean;
  t: any;
  style?: any;
}) {
  const {
    label,
    isActive,
    required,
    onChange,
    disabled,
    t,
    style = {},
  } = props;
  return (
    <div
      style={{ display: "flex", alignItems: "center", gap: "5px", ...style }}
    >
      <Switch checked={isActive} disabled={disabled} onChange={onChange} />
      {label ? (
        <FormLabel>{`${label.toUpperCase()} ${required ? "*" : ""}`}</FormLabel>
      ) : null}
    </div>
  );
}

export function UncontrolledCheckBox(props: {
  label: string;
  isActive?: boolean;
  required?: boolean;
  onChange: any;
  disabled?: boolean;
  t: any;
  uppercase?: boolean;
}) {
  const {
    label,
    isActive,
    required,
    onChange,
    disabled,
    t,
    uppercase = true,
  } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <MuiCheckbox checked={isActive} disabled={disabled} onChange={onChange} />
      {label ? (
        <FormLabel>{`${uppercase ? label.toUpperCase() : label} ${required ? "*" : ""}`}</FormLabel>
      ) : null}
    </div>
  );
}

export function UncontrolledChip(props: {
  label: string;
  isSelected?: boolean;
  required?: boolean;
  onChange: any;
  disabled?: boolean;
  icon: any;
  t: any;
  iconRight?: boolean;
}) {
  const {
    label,
    isSelected,
    required,
    onChange,
    disabled,
    icon,
    t,
    iconRight,
  } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {!iconRight && <span>{icon}</span>}
            <span>{label}</span>
            {iconRight && <span>{icon}</span>}
          </div>
        }
        color="success"
        onClick={onChange}
        variant={isSelected ? "filled" : "outlined"}
        size="medium"
        disabled={disabled}
        sx={{
          margin: "10px",
          backgroundColor: isSelected ? "#2A58D" : "#E1F9F3",
          color: isSelected ? "#FFF" : "#000000",
        }}
      />
    </div>
  );
}

export function UncontrolledSelectWithCheckBoxes(props: {
  label?: string;
  multiple: boolean;
  values: string[];
  setValues: any;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  options: { value: string; label: string }[];
  error: any;
  t: any;
}) {
  const {
    label,
    multiple,
    values,
    setValues,
    placeholder,
    required,
    disabled,
    options,
    error,
    t,
  } = props;
  return (
    <FormBox>
      <MuiSelect
        multiple={multiple}
        displayEmpty
        disabled={disabled}
        style={{
          width: "100%",
          fontSize: "16px",
          textAlign: "center",
          backgroundColor: "#2CA58D",
          color: "white",
          borderRadius: "10px",
          height: "40px",
        }}
        label="category"
        IconComponent={() => null}
        value={values}
        renderValue={(selected) => {
          return <em>{placeholder}</em>;
        }}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            style={{ fontSize: "16px" }}
          >
            <UncontrolledCheckBox
              label={option.label}
              isActive={values.includes(option.value)}
              onChange={() => {
                let tmpValues = [...values];
                if (values.includes(option.value))
                  tmpValues = tmpValues.filter((item) => item !== option.value);
                else
                  tmpValues = multiple
                    ? [...tmpValues, option.value]
                    : [option.value];
                setValues(tmpValues);
              }}
              t={t}
            />
          </MenuItem>
        ))}
      </MuiSelect>
    </FormBox>
  );
}

const IOSSwitch = muiStyled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#4FD1C5",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

export const IOSSwitchLabel = ({
  label,
  value,
  setValue,
  disabled,
  checked,
}: {
  label?: string;
  value?: boolean;
  setValue?: (v: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "7px",
        margin: "15px 0",
        alignItems: "center",
      }}
    >
      <IOSSwitch
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={(e) => setValue && setValue(e.target.checked)}
      />{" "}
      <div style={{ color: "#2CA58D" }}>{label}</div>
    </div>
  );
};
