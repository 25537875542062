import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navigation/Navbar";
import MainTitle from "../components/general/MainTitle";
import { isArrayEmpty, Message } from "../helpers/helpers";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import AlertMessage from "../components/general/AlertMessage";
import { getBookingsToCsv, getBookingsToPdf, getTimeslotBookings, TimeSlotBooking } from "../backend/api/bookings";
import { TextField } from "@mui/material";
import PDFIcon from "images/pdf.png"
import CSVIcon from "images/csv.png"
import BookingsFilter from "components/bookings/BookingsFilter";
import TimeSlotBox from "components/bookings/TimeSlotBox";
import { getUsers } from "backend/api/profile";
import { User } from "types/auth";
import { useDebounce } from "hooks/useDebounce";
import { BookingStatusEnum } from "helpers/constants";

export default function Bookings() {
  const { t } = useTranslation("bookings");
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [bookings, setBookings] = useState<TimeSlotBooking[]>([])
  const [filteredBookings, setFilteredBookings] = useState<TimeSlotBooking[]>([]);
  const [selectedSortFilter, setSelectedSortFilter] = useState("");
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [users, setUsers] = useState<User[]>([])
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const debouncedSearch = useDebounce(search, 2000);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  useQuery({
    queryKey: ["getTimeslotBookings", debouncedSearch, from, to, selectedSortFilter],
    queryFn: () => getTimeslotBookings({ searchField: search, from, to, orderBy: selectedSortFilter }),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    onSuccess(data) {
      setBookings(data)
    },
  });

  const { mutate: exportPdf } = useMutation<Blob, Error>({
    mutationFn: async () => {
      const data = await getBookingsToPdf({ from, to });
      return data;
    },
    onSuccess: (data) => {
      downloadFile(data, "bookings.pdf");
    },
  });

  const { mutate: exportCsv } = useMutation<Blob, Error>({
    mutationFn: async () => {
      const data = await getBookingsToCsv({ from, to });
      return data;
    },
    onSuccess: (data) => {
      downloadFile(data, "bookings.csv");
    },
  });

  useQuery("users", getUsers, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const newData = data.filter((item: any) => item.status !== "DELETED");
      setUsers(newData)
    },
  });

  useEffect(() => {
    const filteredBookingsTmp = bookings?.map((timeSlot) => ({
      ...timeSlot,
      bookings: timeSlot.bookings.filter((booking) => {
        if (selectedActivities.length > 0) {
          return selectedActivities.includes(booking.activity.title)
        } else {
          return true
        }
      })
        .filter((booking) => {
          return booking.status !== BookingStatusEnum.TO_PAY
        })
        .filter((booking) => {
          if (isArrayEmpty(selectedMembers)) {
            return true
          } else {
            return (
              booking.assignedUsers &&
              booking.assignedUsers.some((user: any) => selectedMembers.includes(user.id.toString()))
            )
          }
        })
        .filter((booking) => {
          if (isArrayEmpty(selectedStatus)) return true;
          if (selectedStatus.includes(BookingStatusEnum.CANCELED)) {
            return [BookingStatusEnum.DELETED_BY_CLIENT, ...selectedStatus].includes(booking.status);
          }
          return selectedStatus.includes(booking.status);
        })
    }))
      .filter((timeSlot) => timeSlot.bookings.length > 0)
    setFilteredBookings(filteredBookingsTmp)
  }, [selectedActivities, bookings, selectedMembers, selectedStatus])

  const downloadFile = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Page>
      <Navbar />
      <Container>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <MainTitle title={t("bookingsTitle")} />
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 71 }}>
          <TextField
            size="medium"
            sx={{ textAlign: "center" }}
            fullWidth
            hiddenLabel
            id="fullWidth"
            placeholder={t("bookingFilter.search")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: 10, width: "50%" }}>
            <button onClick={() => exportPdf()} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(132, 220, 198, 0.25)", padding: "6px 8px", borderRadius: 8, border: "none", cursor: "pointer" }}>
              <img alt="pdf" src={PDFIcon} width={25} height={25} />
              <p style={{ margin: 0 }}>{t("exportPdf")}</p>
            </button>
            <button onClick={() => exportCsv()} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(132, 220, 198, 0.25)", padding: "6px 8px", borderRadius: 8, border: "none", cursor: "pointer" }}>
              <img alt="csv" src={CSVIcon} width={25} height={25} />
              <p style={{ margin: 0 }}>{t("exportCsv")}</p>
            </button>
          </div>
        </div>
        <BookingsFilter
          users={users}
          bookings={bookings}
          selectedActivities={selectedActivities}
          setSelectedActivities={setSelectedActivities}
          setSelectedSortFilter={setSelectedSortFilter}
          setFrom={setFrom}
          setTo={setTo}
          from={from}
          to={to}
          t={t}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        {!isArrayEmpty(filteredBookings) && filteredBookings.map((booking, index) => (
          <TimeSlotBox key={index} users={users} booking={booking} t={t} />
        ))}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  position: relative;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
`;
