import activities from "./activities";

const bookings: any = {
  en: {
    ...activities.en,
    bookingsTitle: "My Reservation",
    activityAndSlot: "Activity & Slot",
    numberOfUnit: "Number of people",
    activity: "Activity",
    date: "Date",
    next: "Next",
    previous: "Previous",
    "people(s)": "People(s)",
    payedBy: "Paied by",
    notPaid: "Not paid",
    paid: "Paid",
    notAvailabledActivity: "Activity not available on this day",
    addTheGoodNumberOfPeople: "You should associate ",
    selectASlot: "Select a slot",
    bookingAndPrices: "Booking & Prices",
    fillVisitorData: "Fill customer information",
    selectPricesFor: "Select the prices for the activity",
    priceRecap: "Price recap",
    priceDetails: "Price details",
    bookedSlotFor: "Selected slot for ",
    totalPrice: "Total Price (TTC)",
    confirm: "Confirm",
    remove_filter: "Remove filter",
    person: "Persons",
    staff: "Staff: ",
    externalReference: "External reference of the booking",
    totalReservation: "Total reservation",
    paidVia: "Paid via",
    additionalInformation: "Additional visitor information;",
    tarifs: "Rates",
    seeLess: "See less",
    seeMore: "See more",
    exportPdf: "Export .pdf",
    exportCsv: "Export .csv",
    sortByBooking: "Next reserved dates",
    sortByOrder: "In order of reservation arrival",
    bookingFilter: {
      staff: "Staff member",
      activities: "Activities",
      date: "Sort by date",
      start: "Start",
      end: "End",
      status: "Reservation status",
      search: "Search for bookings by name, email, reference"
    },
    BOOKED: "Reserved",
    CANCELED: "Canceled",
    "NO_SHOW": "No-Show",
    AMENDED: "Modified",
    PENDING: "Pending confirmation",
    // form labels
    name: "name",
    lastName: "last Name",
    fullName: "Full name",
    email: "email",
    phone: "phone",
    // form errors
    too_small: "Mandatory field",
    custom: "Please enter a positive number",
    invalid_type: "Mandatory field",
    invalid_string: "Please enter a well-formatted email",
    slotsAreMandatory: "You have to choose a unique slot",
    MARK_AS_FULL:
      "The slot is marked as full, please remove this restriction and retry",
    UNAVAILABLE: "The slot is marked as canceled, reopen it and retry",
    NOT_AVAILABLE_SLOT: "This slot is no longer available",
    NOT_ENOUGHT_PLACES_ON_SLOT:
      "There are not enough places on the slot, retry on another slot or decrease the number of people",
    DATE_IN_THE_PAST:
      "The start date of the slot is in the past, retry with a valid slot",
    NOT_FOUND: "Data not found",
    INTERNAL_SERVOR_ERROR: "Internal server error, please contact us",
    numberOfUnitInGroup: "person(s) in the group",
    notSelectedTicketsWarning: "You have not entered a number of tickets",
  },
  fr: {
    ...activities.fr,
    bookingsTitle: "Mes Reservations",
    activityAndSlot: "Activité & Créneau",
    numberOfUnit: "Nombre de personnes",
    numberOfUnitInGroup: "personne(s) dans le groupe",
    activity: "Activité",
    date: "Date",
    next: "Suivant",
    previous: "Précédent",
    "people(s)": "Personne(s)",
    payedBy: "Payé par",
    notPaid: "Non payé",
    paid: "Payé",
    notAvailabledActivity: "L'activité n'a pas de créneaux ouverts sur ce jour",
    addTheGoodNumberOfPeople: "Vous devez associer ",
    selectASlot: "Sélectionnez un créneau",
    bookingAndPrices: "Réservation & Tarifs",
    fillVisitorData: "Remplissez les informations client pour la réservation",
    selectPricesFor: "Sélectionnez les tarifs pour l'activité",
    priceRecap: "Récapitulatif du prix",
    priceDetails: "Détails du prix",
    bookedSlotFor: "Créneau sélectionné pour ",
    totalPrice: "Prix total (TTC)",
    confirm: "Confirmer",
    remove_filter: "Retirer le filtre",
    person: "Personnes",
    staff: "Staff: ",
    externalReference: "Référence externe de la réservation",
    totalReservation: "Total de la réservation",
    paidVia: "Payé via",
    additionalInformation: "Informations additionnelles du visiteur ;",
    tarifs: "Tarifs",
    seeLess: "Voir moins",
    seeMore: "Voir plus",
    exportPdf: "Export .pdf",
    exportCsv: "Export .csv",
    sortByBooking: "Prochaines dates réservées",
    sortByOrder: "Par ordre d’arrivée des réservations",
    bookingFilter: {
      staff: "Membre du staff",
      activities: "Activités",
      date: "Tri par date",
      start: "Début",
      end: "Fin",
      status: "Statut de la réservation",
      search: "Recherche de réservations par nom, email, référence"
    },
    BOOKED: "Réservé",
    CANCELED: "Annulé",
    "NO_SHOW": "No-Show",
    AMENDED: "Modifiée",
    PENDING: "En attente de confirmation",
    // form labels
    name: "Prénom",
    lastName: "Nom",
    fullName: "Nom complet",
    email: "Email",
    phone: "Téléphone",
    // form errors
    too_small: "Champ obligatoire",
    custom: "Le nombre doit être positif",
    invalid_type: "Champ obligatoire",
    invalid_string: "Veuillez entrer un email au bon format",
    slotsAreMandatory: "Veuillez choisir un créneau unique",
    MARK_AS_FULL:
      "Le créneau est indiqué comme plein, veuillez enlever la restriction et réessayer",
    UNAVAILABLE:
      "Le créneau est indiqué comme fermé, veuillez le réouvrir et réessayer",
    NOT_AVAILABLE_SLOT: "Le créneau n'est pas disponible",
    NOT_ENOUGHT_PLACES_ON_SLOT: "Il n'y a plus assez de places sur le créneau",
    DATE_IN_THE_PAST:
      "Le créneau indiqué a une date de début dans le passé, veuillez réessayer avec un créneau correct",
    NOT_FOUND: "Donnée non trouvée",
    INTERNAL_SERVOR_ERROR: "Erreur interne, veuillez nous contacter",
    notSelectedTicketsWarning: "Vous n'avez pas saisi un nombre de tickets",
  },
};

export default bookings;
