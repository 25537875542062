const general: any = {
  en: {
    introduceHopbooks: "Introduce HOPBOOKS",
    agenda: "Agenda",
    bookings: "Bookings",
    discounts: "Discounts",
    dashboard: "Dashboard",
    activities: "Activities",
    profile: "Profile",
    alert: "Gauge Alerts",
    website: "Website",
    tools: "Tools",
    needAssistance: "Need Assistance ?",
    needAssistanceText: "Our team will contact you as soons as possible !",
    needAssistanceButton: "Contact Hopleisure",
    logout: "Logout",
    invoicing: "Invoicing",
    save: "Save",
    continueEditing: "Continue Editing",
    LeaveWithoutSaving: "Leave Without Saving",
    saveDataWarningTitle: "Save your data",
    saveDataWarningContent:
      "You have unsaved changes. If you leave this page, your changes will be lost. Do you want to save before leaving?",
    maintenance:
      "We are currently performing maintenance. Please check back soon!",
  },
  fr: {
    introduceHopbooks: "présente HOPBOOKS",
    agenda: "Agenda",
    bookings: "Réservations",
    discounts: "Promotions",
    dashboard: "Tableau de bord",
    activities: "Activités",
    profile: "Mon profil",
    alert: "Alertes de Jauge",
    website: "Site internet",
    tools: "Equipement",
    needAssistance: "Besoin d'aide ?",
    needAssistanceText:
      "Notre équipe reviendra vers vous dans les meilleurs délais !",
    needAssistanceButton: "Contacter Hopleisure",
    logout: "Déconnexion",
    invoicing: "Facturation",
    save: "Sauvegardez",
    continueEditing: "Continuer les modifications",
    LeaveWithoutSaving: "Quitter sans Sauvegarder",
    saveDataWarningTitle: "Sauvegardez vos données",
    saveDataWarningContent:
      "Vous avez des modifications non enregistrées. Si vous quittez cette page, vos modifications seront perdues. Voulez-vous les enregistrer avant de quitter ?",
    maintenance:
      "Nous effectuons actuellement des travaux de maintenance. Merci de revenir bientôt !",
  },
};

export default general;
