import React from "react";
import { useTranslation } from "react-i18next";
import "./Maintenance.css";

const Maintenance = () => {
  const { t } = useTranslation("general");

  return <h2 className="maintenance">{t("maintenance")}</h2>;
};

export default Maintenance;
