const activities: any = {
  en: {
    myActivitiesTitle: "My activities",
    informationsSubTitle: "Informations",
    commercialDescription: "Commercial description",
    activityName: "Activity name",
    activityLanguages: "Available languages",
    category: "Category",
    day: {
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
    },
    // categories
    arcade: "Arcade",
    bowling: "Bowling",
    laserGame: "Laser game",
    paintBall: "Paint ball",
    escapeGame: "Escape game",
    spa: "Spa",
    wellBeing: "Well-being",
    vrSimulation: "VR simulation",
    karting: "Karting",
    cruises: "Cruise",
    indoorGames: "Indoor games",
    accrobranche: "Accrobranche",
    hikingNature: "Hiking",
    outdoorGames: "Outdoor games",
    guidedTour: "Guided tour",
    extremSport: "Extreme sport",
    boatTrip: "Boat tour",
    parachuteJump: "Parachute jump",
    creativeWorkshop: "Creative workshop",
    cookingAndLessons: "Cooking lesson",
    museumsAndExhibitions: "Museums and exhibitions",
    sportAndFitness: "Sport and fitness",

    description: "Description",
    website: "website",
    images: "Images",
    imageSpec: "Limit size - 2.0 MB / Minimum dimension 720 x 480",
    slotDuration: "Slot duration (in minutes)",
    maxCapacity: "Max capacity",
    address: "Address",
    addressDetails: "Address complement",
    phone: "Phone",
    email: "Email",
    cancelation: "Cancellation policy",
    isEnabled: "Enable the activity",
    save: "Save",
    and: "and",

    activityType: "Booking mode",
    GROUP: "Goup Activity",
    INDIVIDUAL: "Individual Activity",
    CANCEL_ANY_TIME: "Free cancellation at any time",
    CANCEL_24_HOURS: "Free cancellation up to 24 hours in advance",
    CANCEL_48_HOURS: "Free cancellation up to 48 hours in advance",
    CANCEL_72_HOURS: "Free cancellation up to 72 hours in advance",
    CANCEL_30_DAYS: "Free cancellation up to 30 days in advance",
    maxUnitsPerGroup: "Max unit by groups",
    maxGroups: "Max groups",
    tooltipActivityLanguages:
      "To add several languages, add a coma between each language",
    languagesExample: "Example: Anglais, Français, Espagnol",
    enterLanguages: "Enter languages",
    other: "Other",
    English: "English",
    French: "French",
    Spanish: "Spanish",

    hoursSubTitle: "Hours",
    noPermanentActivity: "No permanent activity",
    start: "Start",
    end: "End",
    scheduleType: "Schedule Type",
    scheduleTypes: "Schedule type",
    openingHours: "Opening hours",
    openingHoursDesc:
      "if your experience is open without interruption from start to finish on your opening hours without specific slots.",
    openingHoursExample:
      "Example: Your experience is open from 9am to 1pm and then from 3pm to 6pm and your customers can come at any time during these hours on the day of their booking.",
    timeSlots: "Time slots",
    timeSlotsDesc:
      "if your customers come at a specific time slot, and you want slots of a specific duration to be automatically cut out in your schedule between the beginning and end of your entered times.",
    timeSlotsExample:
      "Example: Your experience is open from 10am to 10pm with 1-hour slots. Automatically, twelve 1-hour slots will be cut out and bookable in your schedule.",
    duplicationErrorMsg: "Please add at least one timeslot",
    duplicateTimeslotsToWeek: "Copy opening hours to the whole week",
    duplicateTimeslotsToDaysInRange:
      "Copy opening hours to the days range of temporary activity",
    daysSelectorPlaceholder: "Every day of the week",
    daysSelectorPlaceholderTemporayActivity:
      "Days in range of temporary activity",
    duplicateActionBtn: "Copier opening hours",
    informationImportant: `IMPORTANT: {{slot}} slots will be automatically cut out from your agenda. If the last niche that can be cut between the start and the end of a schedule is less than {{slot}} minutes, it will not be created.`,
    temporaryActivityDatesError:
      "Please set the start date earlier than the end date",
    pictureRequired: "At least one image is required.",
    slotPunctualDates: "Open non-recurring dates",
    attention: "Warning",
    punctualDialogDescription:
      "You  are  about  to  create  ponctual  slots  that  might  overlap  with  existing recurring slots. If you continue, to avoid any conflict, existing recurring slots on the entire day will be cancelled.\n\nIf your existing slots to be cancelled already have bookings, all the bookings will be cancelled too.\n\nDo you wish to continue ?",
    continue: "Continue",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    maintainOverlapBooking:
      "Cancel all reservations and replace the existing slot.",
    replaceOverlapBooking:
      "Replace the slot while keeping the existing reservations.",
    deletePunctualWarningContent:
      "You are about to delete ponctual slots. Be carefull, if there are existing bookings on that slot, they will all be cancelled and your clients will be informed. Do you wish to continue ?",

    pricesSubTitle: "Prices",
    priceType: "Price type",
    priceLabel: "Price name",
    price: "Price",
    priceName: {
      DEFAULT: "Standard price",
      SENIOR: "Child price",
      CHILD: "Senior price",
      GROUP: "Price per group",
    },
    optionnalPriceTypeSubTitle: "* Recommended for external platforms",
    mandatoryPriceTypeSubTitle:
      "* Mandatory to broadcast activity on platforms",

    sellChannelsSubTitle: "Sell channels",
    sellChannelsSectionTitle: "my channels",
    activateActivityWarningTitle: "Activate activity warning",
    deleteActivityWarningTitle: " Warning : delete activity",
    activateActivityWarningContent:
      "To activate this activity, please indicate at least one open schedule and create at least one price rate.",
    deleteActivityWarningContent:
      "Do you want to delete existing bookings on this activity?",
    confirm: "Confirm",
    close: "Close",
    general: "General",
    specialized: "Specialized",
    activityDeletedSuccessfully: "activity is deleted successfully!",
    activityDeletionError: "activity is not deleted!",
    priceNameAlreadyExists: "A price with this name already exists",
    saveActivityInformationsSuccessfully:
      "Activity informations are saved successfully!",
    saveActivityPricesSuccessfully: "Activity prices are saved successfully!",
    saveActivityHoursSuccessfully: "Activity hours are saved successfully!",
    saveActivityChannelsSuccessfully:
      "Activity channels are saved successfully!",

    saveDataHourTitle: "Save your data",
    saveDataHourDescription:
      "Warning, you just created an activity but have not created any opening hours on {{day}}. Please move forward only if you are willing to be closed on these days of the week.",
    saveDataHourCancel: "Back to edition",
    saveDataHourSubmit: "Save my schedule",
    // form errors
    invalid_string: "Invalid email",
    too_small: "Mandatory field",
    "String must contain at least 2 character(s)":
      "String must contain at least 2 character(s)",
    "String must contain at least 3 character(s)":
      "String must contain at least 3 character(s)",
    "String must contain at least 5 character(s)":
      "String must contain at least 5 character(s)",
    "String must contain at least 10 character(s)":
      "String must contain at least 10 character(s)",
    "Expected string, received null": "Expected string, received null",
    "Expected number, received null": "Expected number, received null",
    "Invalid email": "Invalid email",
    "Number must be greater than or equal to 1":
      "Number must be greater than or equal to 1",
    "Number must be greater than or equal to 0":
      "Number must be greater than or equal to 0",
    file_size_max_limit: "Please upload an image with max size 2Mb",
    image_resolution_min_limit:
      "Please enter an image with minimum resolution 720*480 pixels",
    need_fill_slot: "On the {{day}}, you dont have a slot open",

    // api errors
    HOURS_WITH_OVERLAPED:
      "Some hours are overlaping on the same day of your activity",
    HOURS_WITH_FROM_BIGGER_THAN_TO:
      "On the {{day}}, You have an End bigger or equal to a Start on your activity hours",
    HOURS_VALIDATION_ERROR:
      "Warning, your opening hours on {{day}} are not long enough compared to the duration of a slot",
    NEED_TO_FILL_INFORMATIONS_FIRST:
      "You need to save information about your activity first before adding these additional information",
    NOT_FOUND: "Data not found",
    CANT_UPDATE_ACTIVITY_TYPE:
      "It's not possible to update the booking settings type of an activity, please create a new activity with the other type instead",
    INTERNAL_SERVOR_ERROR: "Internal server error, please contact us",
    PUNCTUAL_DATE_OVERLAP_END_DATE:
      "Your activity is temporary. It is not possible to create one-off dates outside of the start and end dates of your temporary activity",
    OVERLAPPING_SLOTS_CREATION:
      "Unable to open. One or more one-off slots may overlap your recurring slots",
  },
  fr: {
    myActivitiesTitle: "Mes activités",
    informationsSubTitle: "Informations",
    commercialDescription: "Description commerciale",
    activityName: "Nom activité",
    activityLanguages: "Langues disponibles",
    category: "Catégorie",
    day: {
      sunday: "Dimanche",
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
    },
    // categories
    arcade: "Arcade",
    bowling: "Bowling",
    laserGame: "Laser game",
    paintBall: "Paint ball",
    escapeGame: "Escape game",
    spa: "Spa",
    wellBeing: "Bien-être",
    vrSimulation: "Simulation VR",
    karting: "Karting",
    cruises: "Croisière",
    indoorGames: "Jeux d'intérieur",
    accrobranche: "Accrobranche",
    hikingNature: "Randonnée",
    outdoorGames: "Jeux d'extérieur",
    guidedTour: "Visite guidée",
    extremSport: "Sport extrême",
    boatTrip: "Tour de bateau",
    parachuteJump: "Saut en parachute",
    creativeWorkshop: "Atelier créatif",
    cookingAndLessons: "Leçon de cuisine",
    museumsAndExhibitions: "Musée et expositions",
    sportAndFitness: "Sport et fitness",

    description: "Description",
    website: "site internet",
    images: "Images",
    imageSpec: "Taille limite - 2.0 Mo / Dimension minimale 720 x 480",
    slotDuration: "Durée de créneau (en minutes)",
    address: "Adresse",
    addressDetails: "Complément d'adresse",
    phone: "Téléphone",
    email: "Email",
    cancelation: "Politique d'annulation",
    isEnabled: "Activer l'activité",
    save: "Sauvegarder",
    and: "et",

    activityType: "Mode de réservation",
    GROUP: "Activité de groupe",
    INDIVIDUAL: "Activité individuelle",
    CANCEL_ANY_TIME: "Annulation sans frais à tout instant",
    CANCEL_24_HOURS: "Annulation sans frais jusqu'à 24h à l'avance",
    CANCEL_48_HOURS: "Annulation sans frais jusqu'à 48h à l'avance",
    CANCEL_72_HOURS: "Annulation sans frais jusqu’à 72h à l'avance",
    CANCEL_30_DAYS: "Annulation sans frais jusqu’à 30 jours à l'avance",
    maxCapacity: "Capacité",
    maxUnitsPerGroup: "Nombre de places par groupe",
    maxGroups: "Nombre maximum de groupes",
    tooltipActivityLanguages:
      "Pour ajouter plusieurs langues, ajoutez une virgule entre chaque langue disponible",
    languagesExample: "Example: Anglais, Français, Espagnol",
    enterLanguages: "Entrez les langues",
    other: "Autre",
    English: "Anglais",
    French: "Français",
    Spanish: "Espagnol",

    hoursSubTitle: "Horaires",
    noPermanentActivity: "Activité temporaire / événement ponctuel",
    start: "Début",
    end: "Fin",
    scheduleType: "Type d'horaire",
    scheduleTypes: "Type d’horaires",
    openingHours: "Horaires d’ouverture",
    openingHoursDesc:
      "si votre expérience est ouverte sans interruption du début à la fin sur vos horaires d’ouverture sans créneaux spécifiques.",
    openingHoursExample:
      "Exemple : Votre activité est ouverte entre 9h à 13h puis de 15h à 18h par exemple, et vos clients peuvent se présenter à tout instant sur ces horaires le jour de leur réservation.",
    timeSlots: "Créneaux horaires",
    timeSlotsDesc:
      "si vos clients se présentent à un créneau horaire précis, et que vous souhaitez que des créneaux d'une durée spécifique soit automatiquement découpés dans votre agenda entre le début et la fin de vos horaires saisis.",
    timeSlotsExample:
      "Exemple : Vous ouvrez votre activité de 10h à 22h avec des créneaux de 1h. Automatiquement, 12 créneaux de 1h seront découpés et réservable individuellement dans votre agenda.",
    duplicationErrorMsg: "Veuillez ajouter au moins un créneau horaire",
    duplicateTimeslotsToWeek: "Copier les horaires sur toute la semaine",
    duplicateTimeslotsToDaysInRange:
      "Copier les heures d'ouverture sur l'intervalle des jours d'activité temporaire",
    daysSelectorPlaceholder: "Tous les jours de la semaine",
    daysSelectorPlaceholderTemporayActivity:
      "Jours dans la plage de l'activité temporaire",
    duplicateActionBtn: "Copier horaires",
    informationImportant: `IMPORTANT: Des créneaux de {{slot}} minutes seront automatiquement découpés dans votre agenda. Si le dernier créneau qui puisse être découpé entre le début et la fin d'un horaire est inférieur à {{slot}} minutes, il ne sera pas créé.`,
    temporaryActivityDatesError:
      "Veuillez définir la date de début avant la date de fin",
    pictureRequired: "Une image est requise.",
    slotPunctualDates: "Ouvrir des dates non récurrentes",
    attention: "Attention",
    punctualDialogDescription:
      "Vous êtes sur le point de créer des créneaux ponctuels qui risquent de chevaucher des créneaux existants. Si vous poursuivez, pour éviter tout conflit, les crénaux existants sur la journée concernée, seront tous supprimés.\n\nSi vos créneaux existants supprimés contiennent des réservations, elles seront aussi toutes annulées pour laisser place à vos nouveaux créneaux ponctuels.\n\nSouhaitez-vous vraiment continuer ?",
    continue: "Continuer",
    cancel: "Annuler",
    yes: "Oui",
    no: "Non",
    maintainOverlapBooking:
      "Annuler toutes les réservations et remplacer le créneau existant.",
    replaceOverlapBooking:
      "Remplacer le créneau tout en conservant les réservations existantes.",
    deletePunctualWarningContent:
      "Vous êtes sur le point de supprimer un créneau ponctuel. Attention, s'il contient des réservations elles seront automatiquement annulées et vos clients seront informés. Souhaitez-vous continuer",

    pricesSubTitle: "Tarifs",
    priceType: "Type de prix",
    priceLabel: "Nom du tarif",
    price: "Prix",
    priceName: {
      DEFAULT: "Tarif Plein",
      SENIOR: "Tarif Senior",
      CHILD: "Tarif Enfant",
      GROUP: "Prix par groupe",
    },
    optionnalPriceTypeSubTitle:
      "* Vivement conseillé pour les plateformes et billetteries",
    mandatoryPriceTypeSubTitle:
      "* Obligatoire pour les plateformes et billetteries",

    sellChannelsSubTitle: "Canaux de vente",
    sellChannelsSectionTitle: "mes canaux",
    activateActivityWarningTitle: " Avertissement : activation d'activité",
    deleteActivityWarningTitle: " Avertissement : suppression d'activité",
    activateActivityWarningContent:
      "Pour activer cette activité, veuillez indiquer au moins un horaire ouvert et créer au moins un tarif",
    deleteActivityWarningContent:
      "Voulez-vous annulez les réservations existantes sur cette activité ?",
    confirm: "Confirmer",
    close: "Fermer",
    general: "Généraux",
    specialized: "Spécialisés",
    activityDeletedSuccessfully: "activité a été supprimée avec succès !",
    activityDeletionError: "activité n'est pas supprimée !",
    priceNameAlreadyExists: "Un tarif avec ce nom existe déjà",
    saveActivityInformationsSuccessfully:
      "Les informations de l'activité ont été enregistrées avec succès!",
    saveActivityPricesSuccessfully:
      "Les prix de l'activité ont été enregistrées avec succès!",
    saveActivityHoursSuccessfully:
      "Les horaires de l'activité ont été enregistrées avec succès!",
    saveActivityChannelsSuccessfully:
      "Les canaux de l'activité ont été enregistrées avec succès!",

    saveDataHourTitle: "Sauvegardez vos données",
    saveDataHourDescription:
      "Attention, vous avez créé une activité pour laquelle vous n'avez pas renseigné d'horaires d'ouverture les {{day}}. Validez seulement si vous êtes fermés sur ces jours de la semaine.",
    saveDataHourCancel: "Retour à l'édition",
    saveDataHourSubmit: "Valider mes horaires",
    // form errors
    invalid_string: "Email invalide",
    too_small: "Le champ est obligatoire",
    "String must contain at least 2 character(s)":
      "Le champ doit contenir au moins 2 caractères",
    "String must contain at least 3 character(s)":
      "Le champ doit contenir au moins 3 caractères",
    "String must contain at least 5 character(s)":
      "Le champ doit contenir au moins 5 caractères",
    "String must contain at least 10 character(s)":
      "Le champ doit contenir au moins 10 caractères",
    "Expected string, received null": "Le champ est obligatoire",
    "Expected number, received null": "Le champ est obligatoire",
    "Invalid email": "Email invalide",
    "Number must be greater than or equal to 1":
      "Veuillez entrer une valeur supérieure ou égale à 1",
    "Number must be greater than or equal to 0":
      "Veuillez entrer une valeur positive",
    file_size_max_limit: "Veuillez entrer une image avec taille maximale 2Mo",
    image_resolution_min_limit:
      "Veuillez entrer une image avec resolution minimale 720*480 pixels",
    need_fill_slot: "Le {{day}}, vous n'avez pas de créneau disponible",

    // api errors
    HOURS_WITH_OVERLAPED: "Certains de vos créneaux se chevauchent sur un jour",
    HOURS_WITH_FROM_BIGGER_THAN_TO:
      "Attention, les {{day}}, un de vos créneaux a un horaire de début postérieur à son horaire de fin",
    HOURS_VALIDATION_ERROR:
      "Attention, vos horaires d'ouverture du {{day}} ne recouvrent pas la durée d'un créneau",
    NEED_TO_FILL_INFORMATIONS_FIRST:
      "Vous devez d'abord renseigner les informations sur votre activité avant de pouvoir modifier ces informations",
    NOT_FOUND: "Données non trouvées",
    CANT_UPDATE_ACTIVITY_TYPE:
      "Il n'est pas possible de modifier le type des paramètres de réservation. Veuillez créer une nouvelle activité avec le type souhaité",
    INTERNAL_SERVOR_ERROR: "Erreur interne du serveur, veuillez nous contacter",
    PUNCTUAL_DATE_OVERLAP_END_DATE:
      "Votre activité est temporaire. Il n'est pas possible de créer des dates ponctuelles en dehors des dates de début et de fin de votre activité temporaire",
    OVERLAPPING_SLOTS_CREATION:
      "Ouverture impossible. Un ou plusieurs créneaux ponctuels risquent de chevaucher vos créneaux récurrents",
  },
};

export default activities;
