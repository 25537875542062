import { arePropsEqual, isArrayEmpty } from "helpers/helpers";
import React, { useEffect, useMemo, useState } from "react";
import SelectActivities from "components/discounts/SelectActivities";
import { Select } from "components/formInputs/Inputs";
import { useForm } from "react-hook-form";
import { isNumber, isString } from "lodash";
import { handleSelectChange } from "components/tabs/widget/WidgetTab.utils";
import { TextField } from "@mui/material";
import { ActivityType, TimeSlotBooking } from "backend/api/bookings";
import { User } from "types/auth";
import SelectMember from "components/agenda/selectMember/SelectMember";
import SelectStatus from "./SelectStatus/SelectStatus";
import { BookingStatusEnum } from "helpers/constants";

const BookingsFilters = (props: {
  bookings: TimeSlotBooking[];
  selectedActivities: string[];
  setSelectedActivities: Function;
  setSelectedSortFilter: Function;
  setFrom: Function;
  setTo: Function;
  selectedMembers: string[];
  setSelectedMembers: Function;
  selectedStatus: string[];
  setSelectedStatus: Function;
  users: User[];
  from: string;
  to: string;
  t: any;
}) => {
  const {
    bookings,
    selectedActivities,
    setSelectedActivities,
    t,
    setSelectedSortFilter,
    selectedMembers,
    setSelectedMembers,
    selectedStatus,
    setSelectedStatus,
    setFrom,
    from,
    to,
    setTo,
    users
  } = props;
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>([]);
  const [activities, setActivities] = useState<ActivityType[]>([]);
  const filtersDateOptions = [{ value: "bookings", label: t("sortByBooking") }, { value: "order", label: t("sortByOrder") }];
  const { control, watch } = useForm({
    defaultValues: { date: "" },
  });

  const statusOptions = useMemo(() => [
    { label: t(BookingStatusEnum.BOOKED), value: BookingStatusEnum.BOOKED, color: "#004643", bgColor: "#D5DB5E" },
    { label: t(BookingStatusEnum.PENDING), value: BookingStatusEnum.PENDING, color: "#000000", bgColor: "rgba(93, 93, 93, 0.4)" },
    { label: t(BookingStatusEnum.NO_SHOW), value: BookingStatusEnum.NO_SHOW, color: "#7E4501", bgColor: "rgba(255, 224, 118, 0.8)" },
    { label: t(BookingStatusEnum.CANCELED), value: BookingStatusEnum.CANCELED, color: "#9A0202", bgColor: "rgba(220, 132, 132, 0.35)" },
    { label: t(BookingStatusEnum.AMENDED), value: BookingStatusEnum.AMENDED, color: "#20408F", bgColor: "rgba(132, 157, 220, 0.35)" },
  ], [t]);

  const selectedSortFilter = watch("date");

  useEffect(() => {
    setSelectedSortFilter(selectedSortFilter);
  }, [selectedSortFilter]);

  useEffect(() => {
    const uniqueActivities = new Map<number, ActivityType>();
    bookings.forEach(timeslot => {
      timeslot.bookings.forEach(booking => {
        if (!uniqueActivities.has(booking.activity.id)) {
          uniqueActivities.set(booking.activity.id, booking.activity);
        }
      });
    });

    setActivities(Array.from(uniqueActivities.values()))
  }, [bookings]);

  useEffect(() => {
    let updatedSelectedActvitiesIds: string[] = [];
    if (!isArrayEmpty(activities) && !isArrayEmpty(selectedActivities)) {
      updatedSelectedActvitiesIds = selectedActivities?.reduce(
        (acc: string[], selectedActivity: string) => {
          const activityId = activities.find(
            (activity: ActivityType) =>
              activity.title === selectedActivity,
          )?.id;
          if (isNumber(activityId)) acc.push(activityId.toString());
          return acc;
        },
        [],
      );
    }
    setSelectedActivityIds(updatedSelectedActvitiesIds);
  }, [activities, selectedActivities]);

  useEffect(() => {
    const activityNames = selectedActivityIds.reduce(
      (acc: string[], selectedActivityId) => {
        const activityName = activities?.find(
          (activity: ActivityType) => activity.id == Number(selectedActivityId),
        )?.title;
        if (isString(activityName)) acc.push(activityName);
        return acc;
      },
      [],
    );
    setSelectedActivities(activityNames);
  }, [selectedActivityIds, activities]);

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 32, width: "100%" }}>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))", gap: 5, width: 700 }}>
        <SelectMember
          selectedMemberIds={selectedMembers}
          users={users}
          handleSelectChange={(event: any) =>
            handleSelectChange(event, setSelectedMembers)
          }
          t={t}
          label={t("bookingFilter.staff")}
          resetSelection={true}
        />
        <SelectActivities
          selectedActivityIds={selectedActivityIds}
          activities={activities}
          handleSelectChange={(event: any) =>
            handleSelectChange(event, setSelectedActivityIds)
          }
          t={t}
          label={t("bookingFilter.activities")}
          resetSelection={true}
        />
        <SelectStatus
          multiple
          placeholder={t("bookingFilter.status")}
          options={statusOptions}
          onChange={(event: any) =>
            handleSelectChange(event, setSelectedStatus)
          }
          values={selectedStatus}
          id="selectStatus"
          name="selectStatus"
          resetSelection
        />
        <Select
          id={"date"}
          name={"date"}
          label={t("bookingFilter.date")}
          options={filtersDateOptions}
          control={control}
          error={""}
          t={t}
          resetSelection={true}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <TextField
            label={t("bookingFilter.start")}
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            sx={{
              width: 160,
              "@media (min-width:1700px)": {
                width: "100%",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              type: "date",
              inputMode: "text",
              max: to
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <TextField
            label={t("bookingFilter.end")}
            type="date"
            onChange={(e) => setTo(e.target.value)}
            sx={{
              width: 160,
              "@media (min-width:1700px)": {
                width: "100%",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              type: "date",
              inputMode: "text",
              min: from
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BookingsFilters, arePropsEqual);
