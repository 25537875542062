import Navbar from "components/navigation/Navbar";
import "./Alert.css";
import MainTitle from "components/general/MainTitle";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { getActivities } from "backend/api/activities";
import AlertHeader from "components/alert/AlertHeader";
import { useForm } from "react-hook-form";
import AlertBody from "components/alert/AlertBody";
import { Button } from "@mui/material";
import {
  ActivityAlert,
  Alert as TAlert,
  getAlert,
  upsertAlert,
} from "backend/api/clients";
import { useCallback, useEffect, useMemo } from "react";
import { queryClient } from "index";
import { toastEmitter } from "helpers/helpers";
import Maintenance from "pages/maintenance/Maintenance";

export default function Alert() {
  const { t } = useTranslation("alert");

  const { control, reset, handleSubmit } = useForm<{
    activities: ActivityAlert[];
  }>({
    mode: "onChange",
    defaultValues: { activities: [] },
  });

  const { mutate } = useMutation(upsertAlert, {
    onSuccess: () => {
      toastEmitter(`${t("success")}`, "success");
      queryClient.invalidateQueries("getAlert");
    },
    onError: (error: any) => {
      toastEmitter(`${t(error?.response?.data?.error)}`, "error");
    },
  });

  const { data: listActivities } = useQuery(
    ["getActivitiesWithDisabled"],
    () => getActivities(true),
    { refetchOnWindowFocus: false },
  );

  const { data: listAlert } = useQuery(["getAlert"], () => getAlert(), {
    refetchOnWindowFocus: false,
  });

  const onSubmit = useCallback(
    (value: { activities: ActivityAlert[] }) => {
      const payload: TAlert[] = value.activities.map(
        ({ activities, activity_alerts }) => ({
          ...activity_alerts,
          priceIds: activities.prices
            .map((price) => price.id!)
            .filter((_, index) => activity_alerts.priceIds[index]),
        }),
      );
      mutate(payload);
    },
    [mutate],
  );

  const activities = useMemo(() => {
    if (!listActivities) return [];
    return listActivities
      .filter((activity) => !activity.informations.isDeleted)
      .map((activities) => {
        const currentAlert = listAlert?.find(
          (alert) => alert?.activities?.id === activities.id,
        );

        return {
          activities,
          activity_alerts: {
            activityId: activities.id,
            id: currentAlert ? currentAlert.activity_alerts.id : undefined,
            priceIds: currentAlert
              ? activities.prices.map((price) =>
                  currentAlert.activity_alerts.priceIds.includes(price.id!),
                )
              : [],
            isSetting: currentAlert
              ? currentAlert.activity_alerts.isSetting
              : false,
            gauge: currentAlert ? currentAlert.activity_alerts.gauge : 0,
            alertStaff: currentAlert
              ? currentAlert.activity_alerts.alertStaff
              : false,
            alertVisitor: currentAlert
              ? currentAlert.activity_alerts.alertVisitor
              : false,
            nonAlertStaff: currentAlert
              ? currentAlert.activity_alerts.nonAlertStaff
              : false,
            autoCancelBooking: currentAlert
              ? currentAlert.activity_alerts.autoCancelBooking
              : false,
            nonHoursReminder: currentAlert
              ? currentAlert.activity_alerts.nonHoursReminder
              : 0,
          },
        };
      });
  }, [listActivities, listAlert]);

  useEffect(() => {
    if (activities.length > 0) {
      reset({ activities });
    }
  }, [activities, reset]);

  return (
    <div className="page-alert">
      <Navbar />
      <Maintenance />
      {/* <div className="container">
        <MainTitle title={t("alertPageTitle")} />
        <form className="form-alert" onSubmit={handleSubmit(onSubmit)}>
          <table className="table-alert">
            <AlertHeader t={t} />
            <AlertBody control={control} />
          </table>
          <div className="wrapper-button">
            <Button variant="contained" type="submit" color="info">
              {t("saveAlert")}
            </Button>
          </div>
        </form>
      </div> */}
    </div>
  );
}
